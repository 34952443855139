import React, { useEffect, useState } from "react";
// import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";
// import OldArticles from "./Articles/OldArticles/OldArticles";
import FeaturedArticles from "./Articles/FeaturedArticles/FeaturedArticles";

export default function Home() {
  const [articles, setArticles] = useState(null);
  const [bestArticles, setBestArticles] = useState(null);

  // Retrieve all articles from database when the page first loads.
  useEffect(() => {
    async function fetchArticles() {
      const response = await fetch(
        "https://etonet-node-server.herokuapp.com/api/articles"
      );
      let articles = await response.json();

      // Optimise this implementation later.
      const edition6 = articles.slice(-9);
      articles.splice(-9);
      const edition5 = articles.slice(-9);
      articles.splice(-9);
      const edition4 = articles.slice(-4);
      articles.splice(-4);
      const edition3 = articles.slice(-8);
      articles.splice(-8);
      const edition2 = articles.slice(-13);
      articles.splice(-13);
      const edition1 = articles.slice(-10);
      articles.splice(-10);
      articles = articles.concat(edition6);
      articles = articles.concat(edition5);
      articles = articles.concat(edition4);
      articles = articles.concat(edition3);
      articles = articles.concat(edition2);
      articles = articles.concat(edition1);

      const bestArticles = [];
      for (let i = 0; i < articles.length; i++) {
        if (
          articles[i]._id === "63637dbe962b618135f1465b" ||
          articles[i]._id === "6362dff736b75ca648f862aa"
        ) {
          bestArticles.push(articles[i]);
        }
      }

      if (response.ok) {
        setArticles(articles);
        setBestArticles(bestArticles);
      }
    }

    fetchArticles();
  }, []);

  return (
    <div>
      {/* <div
        className="search-form-container container"
        id="search-form-container"
      >
        <div className="form-container-inner">
          <form action="" className="form">
            <input
              className="form-input"
              type="text"
              placeholder="What are you looking for?"
            />
            <button className="btn form-btn" type="submit">
              <i className="ri-search-line"></i>
            </button>
          </form>
          <span className="form-note">Or press ESC to close.</span>
        </div>

        <button
          className="btn form-close-btn place-items-center"
          id="form-close-btn"
        >
          <i className="ri-close-line"></i>
        </button>
      </div> */}
      {/* <FeaturedArticles
        sectionTitle="Editor's Choice"
        articles={bestArticles}
      /> */}
      <FeaturedArticles sectionTitle="Latest Articles" articles={articles} />
      {/* Quick read */}

      {/* <OldArticles articles={articles} /> */}

      {/* Popular tags */}
      {/* <section className="popular-tags section">
        <div className="container">
          <h2 className="title section-title" data-name="Popular tags">
            Popular tags
          </h2>

          <div className="popular-tags-container d-grid">
            <Link to="#" className="article">
              <span className="tag-name">#Travel</span>
              <img
                src="./assets/images/tags/travel-tag.jpg"
                alt=""
                className="article-image"
              />
            </Link>

            <Link to="#" className="article">
              <span className="tag-name">#Food</span>
              <img
                src="./assets/images/tags/food-tag.jpg"
                alt=""
                className="article-image"
              />
            </Link>

            <Link to="#" className="article">
              <span className="tag-name">#Technology</span>
              <img
                src="./assets/images/tags/technology-tag.jpg"
                alt=""
                className="article-image"
              />
            </Link>

            <Link to="#" className="article">
              <span className="tag-name">#Health</span>
              <img
                src="./assets/images/tags/health-tag.jpg"
                alt=""
                className="article-image"
              />
            </Link>

            <Link to="#" className="article">
              <span className="tag-name">#Nature</span>
              <img
                src="./assets/images/tags/nature-tag.jpg"
                alt=""
                className="article-image"
              />
            </Link>

            <Link to="#" className="article">
              <span className="tag-name">#Fitness</span>
              <img
                src="./assets/images/tags/fitness-tag.jpg"
                alt=""
                className="article-image"
              />
            </Link>
          </div>
        </div>
      </section> */}

      <section className="newsletter section">
        <div className="container">
          <div className="form-container-inner">
            <h6 className="title newsletter-title">
              More articles coming soon
            </h6>
            <p className="newsletter-description">
              We release 10-15 new articles on both St. Andrew's Day and Fourth
              of June. In the future, we also plan on doing weekly news
              briefings and monthly new articles.
            </p>
          </div>
        </div>
      </section>

      {/* Newsletter */}
      {/* <section className="newsletter section">
        <div className="container">
          <h2 className="title section-title" data-name="Newsletter">
            Newsletter
          </h2>

          <div className="form-container-inner">
            <h6 className="title newsletter-title">Subscribe to Etonet</h6>
            <p className="newsletter-description">
              Lorem ipsum dolor sit amet consectetur adipisicing quaerat
              dignissimos.
            </p>

            <form action="" className="form">
              <input
                className="form-input"
                type="text"
                placeholder="Enter your email address"
              />
              <button className="btn form-btn" type="submit">
                <i className="ri-mail-send-line"></i>
              </button>
            </form>
          </div>
        </div>
      </section> */}
    </div>
  );
}
